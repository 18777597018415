import React, { useEffect, useState } from "react";
import { Tag as AntTag } from "antd"
import { getRandomTagColor, ITagColor } from "@services/theme/tagColors";
import { TagProps } from "./Tag.types"

const Tag: React.FunctionComponent<TagProps> = ({ randomColor, ...props }) => {
    const [tagColor, setTagColor] = useState<ITagColor>()

    /**
     * Will only generate a random tag color on intial render (instead of on every render)
     */
    useEffect(() => {
        const newTagColor = getRandomTagColor()
        setTagColor(newTagColor)
    }, [])

    return (
        <AntTag
            {...props}
            color={randomColor ? tagColor?.color : props.color}
            style={randomColor ? { color: tagColor?.textColor } : undefined}
        />
    );
};

export default Tag;
