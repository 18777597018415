import { AuthenticatedPageWithMenuAndHeader } from "@components/PageWrapper/Authenticated";
import HomePageV2 from "@src/features/homeV2";

const Home = () => {
  return (
    <AuthenticatedPageWithMenuAndHeader>
      <HomePageV2 />
    </AuthenticatedPageWithMenuAndHeader>
  );
};

export default Home;
