import { EventItem, SearchResponse } from "@src/interfaces/Activity";
import APIRoutes from "@src/routes/api/routes";
import { useEffect, useState } from "react";
import { getLocalWorkspaceHeaders } from "@features/auth/useAuth";

export const useAllActivities = (limit?: number) => {
  const [activities, setActivities] = useState<EventItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const pageSize = limit ?? 100;

  const reload = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(APIRoutes.activities.searchAll, {
        method: "POST",
        headers: {
          ...getLocalWorkspaceHeaders(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ params: { pageSize: pageSize } }),
      });

      const res: SearchResponse = await response.json();

      setActivities(res.items);
    } catch (err) {
      setError(err);
      setActivities([]);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    activities,
    isLoading,
    error,
    reload,
  };
};
