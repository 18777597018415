import { Card } from "@components/Card";
import { Title, Text } from "@components/Typography";
import { useTranslation } from "react-i18next";
import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigation } from "@src/features/navigation/hooks";
import FeaturesRoutes from "@src/routes/routes";

const IntegrationsCard: React.FC = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();

  const handleGoTo = async () => {
    await navigateTo(FeaturesRoutes.dataManagement.destinations.home); 
  };

  return (
    <Card className="relative">
      <div className="flex flex-col">
        <Title level={4} className="pb-2">
          {t("Add Integrations")}
        </Title>
        <Text className="pb-1">{t("Integrations allow you to trigger specific actions or send information to an external application.")}</Text>
        <Button icon={<PlusOutlined />} onClick={handleGoTo}>
          {t("Add Integrations")}
        </Button>
      </div>
    </Card>
  );
};

export default IntegrationsCard;
