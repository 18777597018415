import { Card } from "@components/Card";
import { Title, Text } from "@components/Typography";
import { useTranslation } from "react-i18next";
import React from "react";
import { useNavigation } from "@src/features/navigation/hooks";
import { Button } from "antd";
import FeaturesRoutes from "@src/routes/routes";
import { PlusOutlined } from "@ant-design/icons";

const MoreDataCard: React.FC = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();

  const handleGoTo = async () => {
    await navigateTo(FeaturesRoutes.dataManagement.sources.home);
  };

  return (
    <Card className="relative">
      <div className="flex flex-col">
        <Title level={4} className="pb-2">
          {t("Add More Data")}
        </Title>
        <Text className="pb-1">{t("You can feed additional data into your streams by adding more data sources.")}</Text>
        <Button icon={<PlusOutlined />} onClick={handleGoTo}>
          {t("Add More Data")}
        </Button>
      </div>
    </Card>
  );
};

export default MoreDataCard;
