import { allColors } from "./colors";

export type TagColorType = typeof allColors[number];

export interface ITagColor {
  color: TagColorType;
  textColor: "black" | "white";
}

const allTagColors: ITagColor[] = [
  { color: "red", textColor: "black" },
  { color: "green", textColor: "black" },
  { color: "blue", textColor: "black" },
];

export const colorToAntdColor: Record<TagColorType, string> = {
  red: "#ffa39e",
  green: "#b7eb8f",
  blue: "#91d5ff",
  grey: "grey",
};

export const getRandomTagColor = () => {
  return allTagColors[Math.floor(Math.random() * allTagColors.length)];
};

export default allTagColors;
