import { PageSubTitle, PageTitle, Title } from "@components/Typography";
import { useTranslation } from "react-i18next";
import React from "react";
import ActivitiesStreamCard from "./Streams/ActivitiesStreamCard/ActivitiesStreamCard.component";
import IdentitiesStreamCard from "./Streams/IdentitiesStreamCard/IdentitiesStreamCard.component";
import ProfilesStreamCard from "./Streams/ProfilesStreamCard/ProfilesStreamCard.component";
import IntegrationsCard from "./Add/IntegreationsCard/IntegrationsCard.component";
import WorkflowsCard from "./Add/WorkflowsCard/WorkflowsCard.component";
import MoreDataCard from "./Add/MoreDataCard/MoreDataCard.component";
import { WorkspaceAuditStream } from "@features/workspaces/settings/System/workspaces/WorkspaceAuditStream";

const HomePageV2: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col px-5 py-5">
      <PageTitle>{t("Home")}</PageTitle>

      <div className="w-full flex flex-row pb-5 gap-2">
        <div className="w-2/3">
          <Title level={4}>{t("Streams")}</Title>
          <ActivitiesStreamCard />
          <IdentitiesStreamCard />
          <ProfilesStreamCard />
        </div>
        <div className="w-1/3">
          <Title level={4}>{t("Audit")}</Title>
          <WorkspaceAuditStream />
        </div>
      </div>
      <div className="w-full">
        <Title level={4}>{t("That's not all!")}</Title>
        <PageSubTitle className="pb-2">{t("You can also automate actions on your streams!")}</PageSubTitle>
        <div className="flex flex-row gap-2">
          <IntegrationsCard />
          <WorkflowsCard />
          <MoreDataCard />
        </div>
      </div>
    </div>
  );
};

export default HomePageV2;
