import DisplayIf from "@components/Conditionals/DisplayIf";
import { Title, Text } from "@components/Typography";
import CardsSkeleton from "@components/skeletons/CardsSkeleton";
import { useAllActivities } from "@src/features/data-management/activity-stream/activities/hooks/useAllActivities";
import { useNavigation } from "@src/features/navigation/hooks";
import FeaturesRoutes from "@src/routes/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Tag from "@components/Tag";

const ActivitiesStreamCard: React.FC = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();

  const { activities, isLoading } = useAllActivities(10);

  const handleGoTo = async () => {
    await navigateTo(FeaturesRoutes.dataManagement.activityStream.home);
  };

  return (
    <div className="flex flex-col gap-2 pt-1">
      <div className="flex flex-row">
        <Title level={4}>{t("Activities")}</Title>
        <Text onClick={handleGoTo} className="pl-2 pt-1 text-gray-400 cursor-pointer" style={{ textDecoration: "underline" }}>
          {t("View Activity Stream")}
        </Text>
      </div>
      <DisplayIf condition={() => !isLoading} falsy={<CardsSkeleton number={6} />}>
        <DisplayIf condition={() => activities.length > 0} falsy={<div className="py-3">{t("You do not have any activities yet!")}</div>}>
          <div className="flex-grow">
            {activities.map((activity, index) => {
              if (index < 6) {
                return (
                  <div className="inline-block p-0.5" key={index}>
                    <div className="border rounded-md inline-block p-2 flex flex-col bg-white">
                      <Text strong className="text-ellipsis overflow-hidden">
                        {activity.event.type}
                      </Text>
                      <div className="flex flex-row">
                        <Tag className="text-ellipsis overflow-hidden">{Object.keys(activity.event.payload)[0]}</Tag>
                        <Text>{`${activity.event.payload[Object.keys(activity.event.payload)[0]]}`}</Text>
                      </div>
                      <small className="text-right mt-2">{dayjs(activity.event.executedOn).fromNow()}</small>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </DisplayIf>
      </DisplayIf>
    </div>
  );
};

export default ActivitiesStreamCard;
