import DisplayIf from "@components/Conditionals/DisplayIf";
import { Title, Text } from "@components/Typography";
import CardsSkeleton from "@components/skeletons/CardsSkeleton";
import { useNavigation } from "@src/features/navigation/hooks";
import FeaturesRoutes from "@src/routes/routes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAllUniqueIdentities } from "@features/data-management/unique-identity-stream/unique-identities/hooks/useAllUniqueIdentities";
import { Badge } from "antd";
import UniqueIdText from "@features/homeV2/Streams/ProfilesStreamCard/UniqueIdText";
import dayjs from "dayjs";

const ProfilesStreamCard: React.FC = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();
  const { data: uniques = [], isLoading } = useAllUniqueIdentities(10);
  // TODO: use profiles stream query

  const handleGoTo = async () => {
    await navigateTo(FeaturesRoutes.dataManagement.uniqueIdentityStream.home);
  };

  return (
    <div className="flex flex-col gap-2 pt-1">
      <div className="flex flex-row">
        <Title level={4}>{t("Profiles")}</Title>
        <Text onClick={handleGoTo} className="pl-2 pt-1 text-gray-400 cursor-pointer" style={{ textDecoration: "underline" }}>
          {t("View Profiles Stream")}
        </Text>
      </div>
      <DisplayIf condition={() => true} falsy={<CardsSkeleton number={6} />}>
        <DisplayIf condition={() => uniques.length > 0} falsy={<div>{t("You do not have any profiles yet!")}</div>}>
          <div className="flex gap-4 flex-row flex-wrap">
            {uniques.map((activity, i) => {
              return (
                <div key={i} className="border rounded-md p-2 bg-white flex flex-col gap-2 pr-6">
                  <UniqueIdText value={activity.uniqueIdentityId} />
                  <small className="text-right mt-2">{dayjs(activity.lastEventHappenedOn).fromNow()}</small>
                </div>
              );
            })}
          </div>
        </DisplayIf>
      </DisplayIf>
    </div>
  );
};

export default ProfilesStreamCard;
