import { MenuOutlined } from "@ant-design/icons";
import Button from "@components/Button";
import DisplayIf from "@components/Conditionals/DisplayIf";
import Header from "@components/PageWrapper/Header";
import MainNavigation from "@components/PageWrapper/MainNavigation.component";
import Sidebar, { SidebarComponents } from "@components/PageWrapper/sidebars/Sidebar.component";
import { useAuth } from "@src/features/auth/useAuth";
import { useAppDispatch, useAppSelector } from "@src/services/redux";
import { navigationActions, selectNavigation } from "@src/services/redux/reducers/navigation/navigation.reducer";
import { isNullOrEmpty } from "@src/utils/utils";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

export type Props = {
  children: React.ReactNode | React.ReactNode[] | undefined;
};

export const AuthenticatedPageWithMenuAndHeader: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { open } = useAppSelector(selectNavigation);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      router.push("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleExpand = () => {
    dispatch(navigationActions.toggleSidebar());
  };

  const activeCategory = router.route.split("/")[1] as SidebarComponents;
  const hasSidebar = !isNullOrEmpty(activeCategory);

  return (
    <DisplayIf condition={() => isAuthenticated}>
      <div className="flex flex-col h-full min-w-[1366px] font-inter bg-[#f8fafc] dark:bg-gray-900">
        <Header />
        <div className="h-full w-full flex overflow-hidden">
          <MainNavigation activeCategory={activeCategory} />
          <div className="flex-auto relative">
            <div
              className={`absolute top-0 bottom-0 left-0 ${open && hasSidebar ? "w-[230px]" : "w-0"} transition-all bg-white border-r overflow-auto`}
            >
              <Sidebar activeCategory={activeCategory} />
            </div>
            <div className={`absolute top-0 bottom-0 ${open && hasSidebar ? "left-[230px]" : "left-0"} right-0 transition-all`}>
              <div className="h-full w-full overflow-auto">{children}</div>
            </div>
            <DisplayIf condition={() => hasSidebar}>
              <div className={`absolute top-[15px] ${open && hasSidebar ? "left-[217px]" : "left-[-12px]"} transition-all`}>
                <Button icon={<MenuOutlined style={{ fontSize: 10 }} />} size="small" shape="circle" onClick={handleExpand} />
              </div>
            </DisplayIf>
          </div>
        </div>
      </div>
    </DisplayIf>
  );
};

export default AuthenticatedPageWithMenuAndHeader;
