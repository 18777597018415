import DisplayIf from "@components/Conditionals/DisplayIf";
import { useAuth } from "@src/features/auth/useAuth";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

export type Props = {
  children: React.ReactNode | React.ReactNode[] | undefined;
};

export const AuthenticatedEmptyPage: React.FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!isAuthenticated) {
      router.push("/login");
    }
  }, [isAuthenticated]);

  return (
    <DisplayIf condition={() => isAuthenticated}>
      <div className="flex flex-col h-full min-w-[1366px] font-inter bg-[#f8fafc] dark:bg-gray-900">
        <div className="h-full w-full flex overflow-hidden">
          <div className="flex-auto relative">
            <div className="h-full w-full overflow-auto">{children}</div>
          </div>
        </div>
      </div>
    </DisplayIf>
  );
};

export default AuthenticatedEmptyPage;
