import { FlatUniqueIdentity } from "@src/interfaces/UniqueIdentity";
import APIRoutes from "@src/routes/api/routes";
import { browserLogger as logger } from "@src/services/logger";
import { useQuery } from "@tanstack/react-query";
import { getLocalWorkspaceHeaders } from "@features/auth/useAuth";

export const useAllUniqueIdentities = (limit?: number) =>
  useQuery(
    ["unique-identities", limit],
    async () => {
      try {
        const response = await fetch(APIRoutes.uniqueIdentities.searchAll, {
          method: "POST",
          headers: {
            ...getLocalWorkspaceHeaders(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ params: { pageSize: limit } }),
        });
        const res: { items: FlatUniqueIdentity[] } = await response.json();

        return res?.items || [];
      } catch (error) {
        logger.error(error);
        return [];
      }
    },
    {
      cacheTime: 0,
    },
  );
