import { Text } from "@components/Typography/Typography.component";
import { isNullOrEmpty } from "@src/utils/utils";
import React from "react";

export interface Props {
  value: string;
}

const IdText: React.FC<Props> = ({ value }) => {
  if (isNullOrEmpty(value)) return <Text />;
  const parts = value.split("-");
  const last = parts.pop();

  return (
    <Text className="whitespace-nowrap max-w-full m-0 p-0" strong>
      {last}
    </Text>
  );
};

export default IdText;
