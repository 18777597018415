import DisplayIf from "@components/Conditionals/DisplayIf";
import { Title, Text } from "@components/Typography";
import CardsSkeleton from "@components/skeletons/CardsSkeleton";
import { useAllIdentities } from "@src/features/data-management/identity-stream/identities/hooks/useAllIdentities";
import { useNavigation } from "@src/features/navigation/hooks";
import FeaturesRoutes from "@src/routes/routes";
import { Badge } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import IdText from "@features/homeV2/Streams/IdentitiesStreamCard/IdText";

import dayjs from "dayjs";

const IdentitiesStreamCard: React.FC = () => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigation();
  const { data: identities = [], isLoading } = useAllIdentities(10);

  const nbIdentities = identities?.length ?? 0;
  
  const handleGoTo = async () => {
    await navigateTo(FeaturesRoutes.dataManagement.identityStream.home);
  };

  return (
    <div className="flex flex-col gap-2 pt-1">
      <div className="flex flex-row">
        <Title level={4}>{t("Identities")}</Title>
        <Text onClick={handleGoTo} className="pl-2 pt-1 text-gray-400 cursor-pointer" style={{ textDecoration: "underline" }}>
          {t("View Identity Stream")}
        </Text>
      </div>
      <DisplayIf condition={() => !isLoading} falsy={<CardsSkeleton number={6} />}>
        <DisplayIf condition={() => nbIdentities > 0} falsy={<div>{t("You do not have any identities yet!")}</div>}>
          <div className="flex gap-4 flex-row flex-wrap">
            {identities.map((fattenedIdentity, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Badge.Ribbon text={fattenedIdentity.eventCount} color="green" key={index}>
                  <div className="border rounded-md p-2 bg-white flex flex-col gap-2 pr-6">
                    <IdText value={fattenedIdentity.identityId} />
                    <small className="text-right mt-2">{dayjs(fattenedIdentity.lastEventHappenedOn).fromNow()}</small>
                  </div>
                </Badge.Ribbon>
              );
            })}
          </div>
        </DisplayIf>
      </DisplayIf>
    </div>
  );
};

export default IdentitiesStreamCard;
