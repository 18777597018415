import React from "react";
import { Text } from "@components/Typography";
import ListSkeleton from "@components/skeletons/ListSkeleton";
import { useWorkspaceStream } from "@features/workspaces/settings/System/hooks/useWorkspaceStream";
import dayjs from "dayjs";
import IsVisibleWhen from "@components/Conditionals/IsVisibleWhen";
import { useTranslation } from "react-i18next";

export const WorkspaceAuditStream: React.FC = () => {
  const { t } = useTranslation();
  const { data = [], isLoading } = useWorkspaceStream();

  if (isLoading) {
    return <ListSkeleton rows={5} />;
  }

  return (
    <div>
      {data.map((a, i) => {
        return (
          <div key={i} className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
              <div className="m-2 w-2 h-2 border border-gray-200 rounded-2xl bg-gray-200" />
              <IsVisibleWhen condition={!!a.by}>
                <Text strong>{a.by}</Text> <span>{t("executed")}</span> <Text strong>{a.type}</Text>
              </IsVisibleWhen>

              <IsVisibleWhen condition={!a.by}>
                <Text strong>{a.type}</Text>
              </IsVisibleWhen>
            </div>
            <div className="ml-3 pl-5 p-1 border-l border-gray-200 flex flex-col gap-2">
              <Text className="text-xs">{dayjs(a.on).format("MMMM D, YYYY h:mm A")}</Text>

              <Text className="text-xs">{JSON.stringify(a.data)}</Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};
