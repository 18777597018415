import APIRoutes from "@src/routes/api/routes";
import { useQuery } from "@tanstack/react-query";
import { getLocalWorkspaceHeaders } from "@features/auth/useAuth";
import { SystemAuditItem } from "@pages/api/system/stream";
import { WorkspaceAuditItem } from "@pages/api/workspaces/stream";

export const useWorkspaceStream = () => {
  return useQuery(["system", "stream"], async () => {
    const res = await fetch(APIRoutes.workspaces.stream, {
      method: "POST",
      headers: getLocalWorkspaceHeaders(),
    });

    if (!res.ok) {
      throw new Error(await res.text());
    }

    const audits = (await res.json()) as WorkspaceAuditItem[];

    return audits || [];
  });
};
