import React from "react";
import { CardProps } from "./Card.types";

const Card: React.FunctionComponent<CardProps> = ({ children, className = "", ...props }) => {
  return (
    <div className={`w-full h-full bg-white p-5 rounded border ${className}`} {...props}>
      {children}
    </div>
  );
};

export default Card;
