import React from "react";
import { Card as AntCard } from "antd"
import { CardProps } from "./Card.types";


const LegacyCard: React.FunctionComponent<CardProps> = ({ children, title, className = '', ...props }) => {
    return (
        <AntCard
            title={title}
            bodyStyle={{ padding: title ? "1.5rem" : "12px" }}
            className={`bg-white w-full ${className}`}
            {...props}
        >
            {children}
        </AntCard>
    );
};

export default LegacyCard;
