import React from "react";

interface CardsSkeletonProps {
  /**
   * Number of cards to render
   * @default 5
   */
  number?: number;
}

const CardsSkeleton: React.FC<CardsSkeletonProps> = ({ number = 5 }) => {
  return (
    <div className="animate-pulse w-full flex grid grid-cols-3 gap-5 p-5">
      {Array.from({ length: number }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="bg-gray-200 rounded-md h-40 w-full" />
      ))}
    </div>
  );
};

export default CardsSkeleton;
